<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Rapor Özeti</b-card-title>
      <div>
        <b-row class="d-flex align-items-center align-items-center">
          <b-col>
            <b-form-checkbox
              v-model="viewPrevious"
              switch
            >
              Geçmiş Dönem
            </b-form-checkbox>
          </b-col>
          <b-col md="auto">
            <b-button
              :disabled="!reportData.length > 0"
              variant="success"
              size="sm"
              target="_blank"
              :href="downloadUrl"
            >
              <feather-icon icon="DownloadIcon" />
              Excel Detay İndir
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-header>
    <b-table
      striped
      hover
      bordered
      responsive
      :fields="fields"
      :items="reportData"
    >
      <template #cell(title)="data">
        <b-button
          variant="flat-success"
          class="btn-icon rounded-circle"
          :disabled="data.item.users.length === 0"
          @click="setModal(data.item.title,data.item)"
        >
          <FeatherIcon icon="EyeIcon" />
        </b-button>
        {{ data.item.title }}
      </template>
      <template #cell(opens)="data">
        <div v-if="!viewPrevious">
          {{ data.item.opens | toNumber }}
        </div>
        <div
          v-else
          class="d-flex justify-content-end align-items-center"
        >
          <div>
            <div
              v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
              variant="danger"
              class="text-primary width-50 text-center font-weight-bold"
            >
              {{ data.item.opens | toNumber }}
            </div>
            <div
              v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
              variant="danger"
              class="text-danger width-50 text-center border-top"
            >
              {{ data.item.previous_opens | toNumber }}
            </div>
          </div>
          <div class="ml-1">
            <FeatherIcon
              v-if="data.item.opens !== data.item.previous_opens"
              :icon="data.item.opens > data.item.previous_opens? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.item.opens > data.item.previous_opens? 'text-success' : 'text-danger'"
            />
            <FeatherIcon
              v-else
              icon="MinusIcon"
              class="text-muted"
            />
          </div>
        </div>
      </template>
      <template #cell(totals)="data">
        <div v-if="!viewPrevious">
          {{ data.item.totals | toNumber }}
        </div>
        <div
          v-else
          class="d-flex justify-content-end align-items-center"
        >
          <div>
            <div
              v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
              variant="danger"
              class="text-primary width-50 text-center font-weight-bold"
            >
              {{ data.item.totals | toNumber }}
            </div>
            <div
              v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
              variant="danger"
              class="text-danger width-50 text-center border-top"
            >
              {{ data.item.previous_totals | toNumber }}
            </div>
          </div>
          <div class="ml-1">
            <FeatherIcon
              v-if="data.item.totals !== data.item.previous_totals"
              :icon="data.item.totals > data.item.previous_totals? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.item.totals > data.item.previous_totals? 'text-success' : 'text-danger'"
            />
            <FeatherIcon
              v-else
              icon="MinusIcon"
              class="text-muted"
            />
          </div>
        </div>
      </template>
      <template #cell(offTarget)="data">
        <div v-if="!viewPrevious">
          {{ data.item.offTarget | toNumber }}
        </div>
        <div
          v-else
          class="d-flex justify-content-end align-items-center"
        >
          <div>
            <div
              v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
              variant="danger"
              class="text-primary width-50 text-center font-weight-bold"
            >
              {{ data.item.offTarget | toNumber }}
            </div>
            <div
              v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
              variant="danger"
              class="text-danger width-50 text-center border-top"
            >
              {{ data.item.previous_offTarget | toNumber }}
            </div>
          </div>
          <div class="ml-1">
            <FeatherIcon
              v-if="data.item.offTarget !== data.item.previous_offTarget"
              :icon="data.item.offTarget > data.item.previous_offTarget? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.item.offTarget > data.item.previous_offTarget? 'text-success' : 'text-danger'"
            />
            <FeatherIcon
              v-else
              icon="MinusIcon"
              class="text-muted"
            />
          </div>
        </div>
      </template>
      <template #cell(renewed)="data">
        <div v-if="!viewPrevious">
          {{ data.item.renewed | toNumber }}
        </div>
        <div
          v-else
          class="d-flex justify-content-end align-items-center"
        >
          <div>
            <div
              v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
              variant="danger"
              class="text-primary width-50 text-center font-weight-bold"
            >
              {{ data.item.renewed | toNumber }}
            </div>
            <div
              v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
              variant="danger"
              class="text-danger width-50 text-center border-top"
            >
              {{ data.item.previous_renewed | toNumber }}
            </div>
          </div>
          <div class="ml-1">
            <FeatherIcon
              v-if="data.item.renewed !== data.item.previous_renewed"
              :icon="data.item.renewed > data.item.previous_renewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.item.renewed > data.item.previous_renewed? 'text-success' : 'text-danger'"
            />
            <FeatherIcon
              v-else
              icon="MinusIcon"
              class="text-muted"
            />
          </div>
        </div>
      </template>
      <template #cell(notRenewed)="data">
        <div v-if="!viewPrevious">
          {{ data.item.notRenewed | toNumber }}
        </div>
        <div
          v-else
          class="d-flex justify-content-end align-items-center"
        >
          <div>
            <div
              v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
              variant="danger"
              class="text-primary width-50 text-center font-weight-bold"
            >
              {{ data.item.notRenewed | toNumber }}
            </div>
            <div
              v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
              variant="danger"
              class="text-danger width-50 text-center border-top"
            >
              {{ data.item.previous_notRenewed | toNumber }}
            </div>
          </div>
          <div class="ml-1">
            <FeatherIcon
              v-if="data.item.notRenewed !== data.item.previous_notRenewed"
              :icon="data.item.notRenewed > data.item.previous_notRenewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.item.notRenewed > data.item.previous_notRenewed? 'text-success' : 'text-danger'"
            />
            <FeatherIcon
              v-else
              icon="MinusIcon"
              class="text-muted"
            />
          </div>
        </div>
      </template>
      <template #cell(renewedRates)="data">
        <div v-if="!viewPrevious">
          % {{ data.item.renewedRates | toNumber }}
        </div>
        <div
          v-else
          class="d-flex justify-content-end align-items-center"
        >
          <div>
            <div
              v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
              variant="danger"
              class="text-primary width-50 text-center font-weight-bold"
            >
              % {{ data.item.renewedRates | toNumber }}
            </div>
            <div
              v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
              variant="danger"
              class="text-danger width-50 text-center border-top"
            >
              % {{ data.item.previous_renewedRates | toNumber }}
            </div>
          </div>
          <div class="ml-1">
            <FeatherIcon
              v-if="data.item.renewedRates !== data.item.previous_renewedRates"
              :icon="data.item.renewedRates > data.item.previous_renewedRates? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.item.renewedRates > data.item.previous_renewedRates? 'text-success' : 'text-danger'"
            />
            <FeatherIcon
              v-else
              icon="MinusIcon"
              class="text-muted"
            />
          </div>
        </div>
      </template>
      <template #custom-foot>
        <b-tr>
          <b-th class="align-middle">
            Toplam
          </b-th>
          <b-th class="text-right text-nowrap">
            <div v-if="!viewPrevious">
              {{ totalsData.opens | toNumber }}
            </div>
            <div
              v-else
              class="d-flex justify-content-end align-items-center"
            >
              <div>
                <div
                  v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                  variant="danger"
                  class="text-primary width-50 text-center font-weight-bold"
                >
                  {{ totalsData.opens | toNumber }}
                </div>
                <div
                  v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                  variant="danger"
                  class="text-danger width-50 text-center border-top"
                >
                  {{ totalsData.previous_opens | toNumber }}
                </div>
              </div>
              <div class="ml-1">
                <FeatherIcon
                  v-if="totalsData.opens !== totalsData.previous_opens"
                  :icon="totalsData.opens > totalsData.previous_opens? 'ArrowUpIcon' : 'ArrowDownIcon'"
                  :class="totalsData.opens > totalsData.previous_opens? 'text-success' : 'text-danger'"
                />
                <FeatherIcon
                  v-else
                  icon="MinusIcon"
                  class="text-muted"
                />
              </div>
            </div>
          </b-th>
          <b-th class="text-right text-nowrap">
            <div v-if="!viewPrevious">
              {{ totalsData.totals | toNumber }}
            </div>
            <div
              v-else
              class="d-flex justify-content-end align-items-center"
            >
              <div>
                <div
                  v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                  variant="danger"
                  class="text-primary width-50 text-center font-weight-bold"
                >
                  {{ totalsData.totals | toNumber }}
                </div>
                <div
                  v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                  variant="danger"
                  class="text-danger width-50 text-center border-top"
                >
                  {{ totalsData.previous_totals | toNumber }}
                </div>
              </div>
              <div class="ml-1">
                <FeatherIcon
                  v-if="totalsData.totals !== totalsData.previous_totals"
                  :icon="totalsData.totals > totalsData.previous_totals? 'ArrowUpIcon' : 'ArrowDownIcon'"
                  :class="totalsData.totals > totalsData.previous_totals? 'text-success' : 'text-danger'"
                />
                <FeatherIcon
                  v-else
                  icon="MinusIcon"
                  class="text-muted"
                />
              </div>
            </div>
          </b-th>
          <b-th class="text-right text-nowrap">
            <div v-if="!viewPrevious">
              {{ totalsData.offTarget | toNumber }}
            </div>
            <div
              v-else
              class="d-flex justify-content-end align-items-center"
            >
              <div>
                <div
                  v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                  variant="danger"
                  class="text-primary width-50 text-center font-weight-bold"
                >
                  {{ totalsData.offTarget | toNumber }}
                </div>
                <div
                  v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                  variant="danger"
                  class="text-danger width-50 text-center border-top"
                >
                  {{ totalsData.previous_offTarget | toNumber }}
                </div>
              </div>
              <div class="ml-1">
                <FeatherIcon
                  v-if="totalsData.offTarget !== totalsData.previous_offTarget"
                  :icon="totalsData.offTarget > totalsData.previous_offTarget? 'ArrowUpIcon' : 'ArrowDownIcon'"
                  :class="totalsData.offTarget > totalsData.previous_offTarget? 'text-success' : 'text-danger'"
                />
                <FeatherIcon
                  v-else
                  icon="MinusIcon"
                  class="text-muted"
                />
              </div>
            </div>
          </b-th>
          <b-th class="text-right text-nowrap">
            <div v-if="!viewPrevious">
              {{ totalsData.renewed | toNumber }}
            </div>
            <div
              v-else
              class="d-flex justify-content-end align-items-center"
            >
              <div>
                <div
                  v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                  variant="danger"
                  class="text-primary width-50 text-center font-weight-bold"
                >
                  {{ totalsData.renewed | toNumber }}
                </div>
                <div
                  v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                  variant="danger"
                  class="text-danger width-50 text-center border-top"
                >
                  {{ totalsData.previous_renewed | toNumber }}
                </div>
              </div>
              <div class="ml-1">
                <FeatherIcon
                  v-if="totalsData.renewed !== totalsData.previous_renewed"
                  :icon="totalsData.renewed > totalsData.previous_renewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
                  :class="totalsData.renewed > totalsData.previous_renewed? 'text-success' : 'text-danger'"
                />
                <FeatherIcon
                  v-else
                  icon="MinusIcon"
                  class="text-muted"
                />
              </div>
            </div>
          </b-th>
          <b-th class="text-right text-nowrap">
            <div v-if="!viewPrevious">
              {{ totalsData.notRenewed | toNumber }}
            </div>
            <div
              v-else
              class="d-flex justify-content-end align-items-center"
            >
              <div>
                <div
                  v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                  variant="danger"
                  class="text-primary width-50 text-center font-weight-bold"
                >
                  {{ totalsData.notRenewed | toNumber }}
                </div>
                <div
                  v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                  variant="danger"
                  class="text-danger width-50 text-center border-top"
                >
                  {{ totalsData.previous_notRenewed | toNumber }}
                </div>
              </div>
              <div class="ml-1">
                <FeatherIcon
                  v-if="totalsData.notRenewed !== totalsData.previous_notRenewed"
                  :icon="totalsData.notRenewed > totalsData.previous_notRenewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
                  :class="totalsData.notRenewed > totalsData.previous_notRenewed? 'text-success' : 'text-danger'"
                />
                <FeatherIcon
                  v-else
                  icon="MinusIcon"
                  class="text-muted"
                />
              </div>
            </div>
          </b-th>
          <b-th class="text-right text-nowrap">
            <div v-if="!viewPrevious">
              % {{ totalsData.renewedRates | toNumber }}
            </div>
            <div
              v-else
              class="d-flex justify-content-end align-items-center"
            >
              <div>
                <div
                  v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                  variant="danger"
                  class="text-primary width-50 text-center font-weight-bold"
                >
                  % {{ totalsData.renewedRates | toNumber }}
                </div>
                <div
                  v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                  variant="danger"
                  class="text-danger width-50 text-center border-top"
                >
                  % {{ totalsData.previous_renewedRates | toNumber }}
                </div>
              </div>
              <div class="ml-1">
                <FeatherIcon
                  v-if="totalsData.renewedRates !== totalsData.previous_renewedRates"
                  :icon="totalsData.renewedRates > totalsData.previous_renewedRates? 'ArrowUpIcon' : 'ArrowDownIcon'"
                  :class="totalsData.renewedRates > totalsData.previous_renewedRates? 'text-success' : 'text-danger'"
                />
                <FeatherIcon
                  v-else
                  icon="MinusIcon"
                  class="text-muted"
                />
              </div>
            </div>
          </b-th>
        </b-tr>
      </template>
    </b-table>
    <b-modal
      id="detail-modal"
      v-model="detailModal"
      centered
      size="xl"
      :title="modalData.title"
      hide-footer
      scrollable
      body-class="p-0"
    >
      <b-table
        striped
        hover
        responsive
        :fields="modalFields"
        :items="modalData.data"
      >
        <template #cell(username)="data">
          {{ (data.item.username)? data.item.username : 'Tanımsız' }}
        </template>
        <template #cell(opens)="row">
          <div v-if="!viewPrevious">
            {{ row.item.opens | toNumber }}
          </div>
          <div
            v-else
            class="d-flex justify-content-end align-items-center"
          >
            <div>
              <div
                v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                variant="danger"
                class="text-primary width-50 text-center font-weight-bold"
              >
                {{ row.item.opens | toNumber }}
              </div>
              <div
                v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                variant="danger"
                class="text-danger width-50 text-center border-top"
              >
                {{ row.item.previous_opens | toNumber }}
              </div>
            </div>
            <div class="ml-1">
              <FeatherIcon
                v-if="row.item.opens !== row.item.previous_opens"
                :icon="row.item.opens > row.item.previous_opens? 'ArrowUpIcon' : 'ArrowDownIcon'"
                :class="row.item.opens > row.item.previous_opens? 'text-success' : 'text-danger'"
              />
              <FeatherIcon
                v-else
                icon="MinusIcon"
                class="text-muted"
              />
            </div>
          </div>
        </template>
        <template #cell(totals)="row">
          <div v-if="!viewPrevious">
            {{ row.item.totals | toNumber }}
          </div>
          <div
            v-else
            class="d-flex justify-content-end align-items-center"
          >
            <div>
              <div
                v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                variant="danger"
                class="text-primary width-50 text-center font-weight-bold"
              >
                {{ row.item.totals | toNumber }}
              </div>
              <div
                v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                variant="danger"
                class="text-danger width-50 text-center border-top"
              >
                {{ row.item.previous_totals | toNumber }}
              </div>
            </div>
            <div class="ml-1">
              <FeatherIcon
                v-if="row.item.totals !== row.item.previous_totals"
                :icon="row.item.totals > row.item.previous_totals? 'ArrowUpIcon' : 'ArrowDownIcon'"
                :class="row.item.totals > row.item.previous_totals? 'text-success' : 'text-danger'"
              />
              <FeatherIcon
                v-else
                icon="MinusIcon"
                class="text-muted"
              />
            </div>
          </div>
        </template>
        <template #cell(offTarget)="row">
          <div v-if="!viewPrevious">
            {{ row.item.offTarget | toNumber }}
          </div>
          <div
            v-else
            class="d-flex justify-content-end align-items-center"
          >
            <div>
              <div
                v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                variant="danger"
                class="text-primary width-50 text-center font-weight-bold"
              >
                {{ row.item.offTarget | toNumber }}
              </div>
              <div
                v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                variant="danger"
                class="text-danger width-50 text-center border-top"
              >
                {{ row.item.previous_offTarget | toNumber }}
              </div>
            </div>
            <div class="ml-1">
              <FeatherIcon
                v-if="row.item.offTarget !== row.item.previous_offTarget"
                :icon="row.item.offTarget > row.item.previous_offTarget? 'ArrowUpIcon' : 'ArrowDownIcon'"
                :class="row.item.offTarget > row.item.previous_offTarget? 'text-success' : 'text-danger'"
              />
              <FeatherIcon
                v-else
                icon="MinusIcon"
                class="text-muted"
              />
            </div>
          </div>
        </template>
        <template #cell(renewed)="row">
          <div v-if="!viewPrevious">
            {{ row.item.renewed | toNumber }}
          </div>
          <div
            v-else
            class="d-flex justify-content-end align-items-center"
          >
            <div>
              <div
                v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                variant="danger"
                class="text-primary width-50 text-center font-weight-bold"
              >
                {{ row.item.renewed | toNumber }}
              </div>
              <div
                v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                variant="danger"
                class="text-danger width-50 text-center border-top"
              >
                {{ row.item.previous_renewed | toNumber }}
              </div>
            </div>
            <div class="ml-1">
              <FeatherIcon
                v-if="row.item.renewed !== row.item.previous_renewed"
                :icon="row.item.renewed > row.item.previous_renewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
                :class="row.item.renewed > row.item.previous_renewed? 'text-success' : 'text-danger'"
              />
              <FeatherIcon
                v-else
                icon="MinusIcon"
                class="text-muted"
              />
            </div>
          </div>
        </template>
        <template #cell(notRenewed)="row">
          <div v-if="!viewPrevious">
            {{ row.item.notRenewed | toNumber }}
          </div>
          <div
            v-else
            class="d-flex justify-content-end align-items-center"
          >
            <div>
              <div
                v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                variant="danger"
                class="text-primary width-50 text-center font-weight-bold"
              >
                {{ row.item.notRenewed | toNumber }}
              </div>
              <div
                v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                variant="danger"
                class="text-danger width-50 text-center border-top"
              >
                {{ row.item.previous_notRenewed | toNumber }}
              </div>
            </div>
            <div class="ml-1">
              <FeatherIcon
                v-if="row.item.notRenewed !== row.item.previous_notRenewed"
                :icon="row.item.notRenewed > row.item.previous_notRenewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
                :class="row.item.notRenewed > row.item.previous_notRenewed? 'text-success' : 'text-danger'"
              />
              <FeatherIcon
                v-else
                icon="MinusIcon"
                class="text-muted"
              />
            </div>
          </div>
        </template>
        <template #cell(renewedRates)="row">
          <div v-if="!viewPrevious">
            % {{ row.item.renewedRates | toNumber }}
          </div>
          <div
            v-else
            class="d-flex justify-content-end align-items-center text-nowrap"
          >
            <div>
              <div
                v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                variant="danger"
                class="text-primary width-50 text-center font-weight-bold"
              >
                % {{ row.item.renewedRates | toNumber }}
              </div>
              <div
                v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                variant="danger"
                class="text-danger width-50 text-center border-top"
              >
                % {{ row.item.previous_renewedRates | toNumber }}
              </div>
            </div>
            <div class="ml-1">
              <FeatherIcon
                v-if="row.item.renewedRates !== row.item.previous_renewedRates"
                :icon="row.item.renewedRates > row.item.previous_renewedRates? 'ArrowUpIcon' : 'ArrowDownIcon'"
                :class="row.item.renewedRates > row.item.previous_renewedRates? 'text-success' : 'text-danger'"
              />
              <FeatherIcon
                v-else
                icon="MinusIcon"
                class="text-muted"
              />
            </div>
          </div>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th class="align-middle">
              Toplam
            </b-th>
            <b-th class="text-right text-nowrap">
              <div v-if="!viewPrevious">
                {{ modalData.totals.opens | toNumber }}
              </div>
              <div
                v-else
                class="d-flex justify-content-end align-items-center"
              >
                <div>
                  <div
                    v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                    variant="danger"
                    class="text-primary width-50 text-center font-weight-bold"
                  >
                    {{ modalData.totals.opens | toNumber }}
                  </div>
                  <div
                    v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                    variant="danger"
                    class="text-danger width-50 text-center border-top"
                  >
                    {{ modalData.totals.previous_opens | toNumber }}
                  </div>
                </div>
                <div class="ml-1">
                  <FeatherIcon
                    v-if="modalData.totals.opens !== modalData.totals.previous_opens"
                    :icon="modalData.totals.opens > modalData.totals.previous_opens? 'ArrowUpIcon' : 'ArrowDownIcon'"
                    :class="modalData.totals.opens > modalData.totals.previous_opens? 'text-success' : 'text-danger'"
                  />
                  <FeatherIcon
                    v-else
                    icon="MinusIcon"
                    class="text-muted"
                  />
                </div>
              </div>
            </b-th>
            <b-th class="text-right text-nowrap">
              <div v-if="!viewPrevious">
                {{ modalData.totals.totals | toNumber }}
              </div>
              <div
                v-else
                class="d-flex justify-content-end align-items-center"
              >
                <div>
                  <div
                    v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                    variant="danger"
                    class="text-primary width-50 text-center font-weight-bold"
                  >
                    {{ modalData.totals.totals | toNumber }}
                  </div>
                  <div
                    v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                    variant="danger"
                    class="text-danger width-50 text-center border-top"
                  >
                    {{ modalData.totals.previous_totals | toNumber }}
                  </div>
                </div>
                <div class="ml-1">
                  <FeatherIcon
                    v-if="modalData.totals.totals !== modalData.totals.previous_totals"
                    :icon="modalData.totals.totals > modalData.totals.previous_totals? 'ArrowUpIcon' : 'ArrowDownIcon'"
                    :class="modalData.totals.totals > modalData.totals.previous_totals? 'text-success' : 'text-danger'"
                  />
                  <FeatherIcon
                    v-else
                    icon="MinusIcon"
                    class="text-muted"
                  />
                </div>
              </div>
            </b-th>
            <b-th class="text-right text-nowrap">
              <div v-if="!viewPrevious">
                {{ modalData.totals.offTarget | toNumber }}
              </div>
              <div
                v-else
                class="d-flex justify-content-end align-items-center"
              >
                <div>
                  <div
                    v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                    variant="danger"
                    class="text-primary width-50 text-center font-weight-bold"
                  >
                    {{ modalData.totals.offTarget | toNumber }}
                  </div>
                  <div
                    v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                    variant="danger"
                    class="text-danger width-50 text-center border-top"
                  >
                    {{ modalData.totals.previous_offTarget | toNumber }}
                  </div>
                </div>
                <div class="ml-1">
                  <FeatherIcon
                    v-if="modalData.totals.offTarget !== modalData.totals.previous_offTarget"
                    :icon="modalData.totals.offTarget > modalData.totals.previous_offTarget? 'ArrowUpIcon' : 'ArrowDownIcon'"
                    :class="modalData.totals.offTarget > modalData.totals.previous_offTarget? 'text-success' : 'text-danger'"
                  />
                  <FeatherIcon
                    v-else
                    icon="MinusIcon"
                    class="text-muted"
                  />
                </div>
              </div>
            </b-th>
            <b-th class="text-right text-nowrap">
              <div v-if="!viewPrevious">
                {{ modalData.totals.renewed | toNumber }}
              </div>
              <div
                v-else
                class="d-flex justify-content-end align-items-center"
              >
                <div>
                  <div
                    v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                    variant="danger"
                    class="text-primary width-50 text-center font-weight-bold"
                  >
                    {{ modalData.totals.renewed | toNumber }}
                  </div>
                  <div
                    v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                    variant="danger"
                    class="text-danger width-50 text-center border-top"
                  >
                    {{ modalData.totals.previous_renewed | toNumber }}
                  </div>
                </div>
                <div class="ml-1">
                  <FeatherIcon
                    v-if="modalData.totals.renewed !== modalData.totals.previous_renewed"
                    :icon="modalData.totals.renewed > modalData.totals.previous_renewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
                    :class="modalData.totals.renewed > modalData.totals.previous_renewed? 'text-success' : 'text-danger'"
                  />
                  <FeatherIcon
                    v-else
                    icon="MinusIcon"
                    class="text-muted"
                  />
                </div>
              </div>
            </b-th>
            <b-th class="text-right text-nowrap">
              <div v-if="!viewPrevious">
                {{ modalData.totals.notRenewed | toNumber }}
              </div>
              <div
                v-else
                class="d-flex justify-content-end align-items-center"
              >
                <div>
                  <div
                    v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                    variant="danger"
                    class="text-primary width-50 text-center font-weight-bold"
                  >
                    {{ modalData.totals.notRenewed | toNumber }}
                  </div>
                  <div
                    v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                    variant="danger"
                    class="text-danger width-50 text-center border-top"
                  >
                    {{ modalData.totals.previous_notRenewed | toNumber }}
                  </div>
                </div>
                <div class="ml-1">
                  <FeatherIcon
                    v-if="modalData.totals.notRenewed !== modalData.totals.previous_notRenewed"
                    :icon="modalData.totals.notRenewed > modalData.totals.previous_notRenewed? 'ArrowUpIcon' : 'ArrowDownIcon'"
                    :class="modalData.totals.notRenewed > modalData.totals.previous_notRenewed? 'text-success' : 'text-danger'"
                  />
                  <FeatherIcon
                    v-else
                    icon="MinusIcon"
                    class="text-muted"
                  />
                </div>
              </div>
            </b-th>
            <b-th class="text-right text-nowrap">
              <div v-if="!viewPrevious">
                %{{ modalData.totals.renewedRates | toNumber }}
              </div>
              <div
                v-else
                class="d-flex justify-content-end align-items-center"
              >
                <div>
                  <div
                    v-b-tooltip.hover.right.v-primary="'Güncel Dönem'"
                    variant="danger"
                    class="text-primary width-50 text-center font-weight-bold"
                  >
                    %{{ modalData.totals.renewedRates | toNumber }}
                  </div>
                  <div
                    v-b-tooltip.hover.right.v-danger="'Geçmiş Dönem'"
                    variant="danger"
                    class="text-danger width-50 text-center border-top"
                  >
                    %{{ modalData.totals.previous_renewedRates | toNumber }}
                  </div>
                </div>
                <div class="ml-1">
                  <FeatherIcon
                    v-if="modalData.totals.renewedRates !== modalData.totals.previous_renewedRates"
                    :icon="modalData.totals.renewedRates > modalData.totals.previous_renewedRates? 'ArrowUpIcon' : 'ArrowDownIcon'"
                    :class="modalData.totals.renewedRates > modalData.totals.previous_renewedRates? 'text-success' : 'text-danger'"
                  />
                  <FeatherIcon
                    v-else
                    icon="MinusIcon"
                    class="text-muted"
                  />
                </div>
              </div>
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardHeader, BCardTitle, BTr, BTh, BButton, BModal, VBTooltip, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Summary',
  components: {
    BCard,
    BTable,
    BCardHeader,
    BCardTitle,
    BTr,
    BTh,
    BButton,
    BModal,
    BFormCheckbox,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    reportData: {
      type: Array,
      required: true,
    },
    totalsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewPrevious: false,
      detailModal: false,
      modalData: {
        title: 'Detay',
        data: [],
        totals: {},
      },
      fields: [
        {
          key: 'title', label: 'YENİLEME', sortable: true, thClass: 'align-middle table-middle',
        },
        {
          key: 'opens', label: 'AÇIK GÖRÜŞME', sortable: true, thClass: 'text-left font-small-2 width-150 align-middle table-middle', tdClass: 'text-right',
        },
        {
          key: 'totals', label: 'TOPLAM ADET', sortable: true, thClass: 'text-left font-small-2 width-150 align-middle table-middle', tdClass: 'text-right text-nowrap',
        },
        {
          key: 'offTarget', label: 'HEDEF DIŞI', sortable: true, thClass: 'text-left font-small-2 width-150 align-middle table-middle', tdClass: 'text-right text-nowrap',
        },
        {
          key: 'renewed', label: 'YENİLEME YAPILAN', sortable: true, thClass: 'text-left font-small-2 width-150 align-middle table-middle', tdClass: 'text-right text-nowrap',
        },
        {
          key: 'notRenewed', label: 'YENİLEME YAPILMAYAN', sortable: true, thClass: 'text-left font-small-2 width-150 align-middle table-middle', tdClass: 'text-right text-nowrap',
        },
        {
          key: 'renewedRates', label: 'YENİLEME ORANI', sortable: true, thClass: 'text-left font-small-2 width-150 align-middle table-middle', tdClass: 'text-right text-nowrap',
        },
      ],
      modalFields: [
        {
          key: 'username', label: 'TEMSİLCİ', sortable: true, thClass: 'align-middle table-middle',
        },
        {
          key: 'opens', label: 'AÇIK GÖRÜŞME', sortable: true, thClass: 'text-right width-150 align-middle table-middle', tdClass: 'text-right',
        },
        {
          key: 'totals', label: 'TOPLAM ADET', sortable: true, thClass: 'text-right width-150 align-middle table-middle', tdClass: 'text-right',
        },
        {
          key: 'offTarget', label: 'HEDEF DIŞI', sortable: true, thClass: 'text-right width-150 align-middle table-middle', tdClass: 'text-right',
        },
        {
          key: 'renewed', label: 'YENİLEME YAPILAN', sortable: true, thClass: 'text-right width-150 align-middle table-middle', tdClass: 'text-right',
        },
        {
          key: 'notRenewed', label: 'YENİLEME YAPILMAYAN', sortable: true, thClass: 'text-right width-150 align-middle table-middle', tdClass: 'text-right',
        },
        {
          key: 'renewedRates', label: 'YENİLEME ORANI', sortable: true, thClass: 'text-right width-150 align-middle table-middle', tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/reports/insurance/renewals_export/excel?token=${localStorage.getItem('downloadToken')}&sdate=${this.filterData.sdate}&edate=${this.filterData.edate}&company=${this.filterData.company}&id_com_user=${this.filterData.id_com_user}&id_com_insurance_renewal_periods=${this.filterData.id_com_insurance_renewal_periods}`
    },
    filterData() {
      return this.$store.getters['insuranceRenewalsReport/getFilterData']
    },
  },
  methods: {
    setModal(text, data) {
      this.modalData.title = text
      this.modalData.data = data.users
      this.modalData.totals = data
      this.detailModal = true
    },
  },
}
</script>
